import ContentLoader from "react-content-loader";
// Flere loaders her https://skeletonreact.com/

export const Loader = () => (
  <ContentLoader speed={2} width="100%" height={100} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="8" />
    <rect x="0" y="28" rx="4" ry="4" width="100%" height="8" />
    <rect x="0" y="48" rx="4" ry="4" width="90%" height="8" />
    <rect x="0" y="68" rx="4" ry="4" width="50%" height="8" />
  </ContentLoader>
);
