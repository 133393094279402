import React from "react";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Loader } from "../ui/loader";

export interface BookingButtonSkeletonsProps {
  number?: number;
}

export const BookingButtonSkeletons = ({ number }: BookingButtonSkeletonsProps) => (
  <>
    {Array(number)
      .fill(0)
      .map((_, index) => (
        <React.Fragment key={index}>
          <div className="booking-button booking-button--loading">
            <Loader />
          </div>
          <HorizontalSpacer />
        </React.Fragment>
      ))}
  </>
);
