import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

export interface GraphQLErrorExtended extends GraphQLError {
  extensions: {
    code: string;
    [key: string]: string; // Include this line if the extensions object can have any other arbitrary properties
  };
}

export function parseGraphqlError(error: unknown) {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors.length > 0) {
      const graphqlError = error.graphQLErrors[0];
      if ((graphqlError as GraphQLErrorExtended).extensions !== undefined) {
        return (graphqlError as GraphQLErrorExtended).extensions.code;
      } else {
        return graphqlError.message;
      }
    }
  } else if (error instanceof GraphQLError) {
    return error.message;
  }
  throw new Error(`Could not parse error message ${error}`);
}
