"use client";
import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateServiceBookingOrderMutation,
  CreateServiceBookingOrderDocument,
  CancelServiceBookingOrderMutation,
  CancelServiceBookingOrderDocument,
  OrderPartsFragment,
  UpdateServiceBookingOrderMutation,
  UpdateServiceBookingOrderDocument,
  OrderItem,
  GetServiceOrderQuery,
  GetServiceOrderDocument
} from "@/generated/client.generated";
import { parseGraphqlError } from "@/utils/graphql";
import { ROOT_URL, paths } from "@/utils/paths-utils";
import { UserContext } from "@/contexts/user-context";
import { GAItem, addToCartEvent } from "../analytics/event-logger";
import { sum } from "rambda";

interface UseServiceBookingProps {
  id?: string;
}
export const useServiceBooking = ({ id }: UseServiceBookingProps) => {
  const { refetchMyBookedActivities } = useContext(UserContext);

  const [createBooking, { loading: createBookingLoading }] = useMutation<CreateServiceBookingOrderMutation>(
    CreateServiceBookingOrderDocument
  );
  const [cancelBooking, { loading: cancelBookingLoading }] = useMutation<CancelServiceBookingOrderMutation>(
    CancelServiceBookingOrderDocument
  );
  const [updateBooking, { loading: updateBookingLoading }] = useMutation<UpdateServiceBookingOrderMutation>(
    UpdateServiceBookingOrderDocument
  );
  const [getOrder, { loading: getOrderLoading }] = useLazyQuery<GetServiceOrderQuery>(GetServiceOrderDocument);

  useEffect(() => {
    async function getServiceOrder(id: string) {
      try {
        await getOrder({ variables: { id: id, redirectUrl: `${ROOT_URL}${paths.bookingReceipt(id)}` } }).then(
          (data) => {
            setBooking(data.data?.Order);
          }
        );
      } catch (error) {
        setError(parseGraphqlError(error));
      }
    }
    if (id) {
      getServiceOrder(id);
    }
  }, [id, getOrder]);

  const [booking, setBooking] = useState<OrderPartsFragment | undefined>(undefined);

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (createBookingLoading || cancelBookingLoading || getOrderLoading || updateBookingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createBookingLoading, cancelBookingLoading, getOrderLoading, updateBookingLoading]);

  const handleUpdateOrder = async (id: string, orderItems: Array<OrderItem>) => {
    setError(undefined);
    setConfirmation(undefined);
    const redirectUrl = `${ROOT_URL}${paths.bookingReceipt(id)}`;

    try {
      const response = await updateBooking({
        onCompleted: () => refetchMyBookedActivities(),
        variables: {
          id: id,
          orderItems: orderItems,
          redirectUrl: redirectUrl
        }
      });
      if (!response.data?.UpdateOrder.paymentLink) {
        setError("Kunne ikke oppdatere bestillingen. Prøv igjen senere");
      } else {
        setBooking(response.data?.UpdateOrder);
      }
    } catch (error) {
      setError(parseGraphqlError(error));
    }
  };

  const handleCancelBooking = async (id: string) => {
    setError(undefined);
    setConfirmation(undefined);
    try {
      await cancelBooking({
        onCompleted: () => refetchMyBookedActivities(),
        variables: { id }
      });
      setBooking(undefined);
      setConfirmation("Timen er avbooket. Velkommen tilbake!");
    } catch (error) {
      setError(parseGraphqlError(error));
    }
  };

  const handleBooking = async (orderItems: Array<OrderItem>, centerId?: string) => {
    setError(undefined);
    setConfirmation(undefined);
    const redirectUrl = `${ROOT_URL}${paths.bookingReceipt(id ?? "")}`;

    try {
      const response = await createBooking({
        onCompleted: () => {
          refetchMyBookedActivities();
          addToCartEvent(
            sum(orderItems.map((item) => item.amount?.amount ?? 0)) / 100,
            orderItems.map((item) => {
              return {
                item_brand: "3T",
                item_id: item.resourceId,
                item_name: item.productId,
                price: (item.amount?.amount ?? 0) / 100,
                quantity: 1
              } as GAItem;
            })
          );
        },
        variables: {
          centerId: centerId,
          orderItems: orderItems,
          redirectUrl: redirectUrl
        }
      });
      if (!response.data?.CreateOrder.paymentLink) {
        setError("Could not create order");
      } else {
        setBooking(response.data?.CreateOrder);
      }
    } catch (error) {
      setError(parseGraphqlError(error));
    }
  };

  return { booking, confirmation, error, handleBooking, handleCancelBooking, handleUpdateOrder, loading };
};
